<template>
	<div>
		<div>
			<div class="tw-flex tw-items-center">
				<v-toolbar-title class="tw-font-semibold tw-text-22">
					<div>{{ $t('banners') }}</div>
					<div class="tw-font-normal tw-text-lg">
						Banners have to contain 16 / 5 aspect ratio or banners will be
						cutout from left or top.
					</div>
				</v-toolbar-title>

				<v-spacer />

				<div class="tw-flex tw-items-center">
					<v-btn
						:loading="isCreating"
						:height="$vuetify.breakpoint.mdAndDown ? 40 : 50"
						dark
						depressed
						color="#03735D"
						class="tw-ms-2 xl:tw-ms-5"
						@click="onCreateBanner"
					>
						<v-icon>mdi mdi-plus</v-icon>
					</v-btn>
				</div>
			</div>

			<div class="tw-mt-5 tw-space-y-4">
				<v-card
					v-for="banner in $banners.data"
					:key="banner.id"
					outlined
					class="tw-group tw-rounded-xl tw-overflow-hidden"
				>
					<div
						class="tw-z-10 tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center tw-bg-black tw-bg-opacity-80 tw-opacity-0 group-hover:tw-opacity-100 tw-transition-all tw-duration-500 tw-space-x-2"
						:class="[deleting === banner.id ? 'tw-opacity-100 ' : '']"
					>
						<v-btn
							icon
							tag="a"
							width="64"
							height="64"
							target="_blank"
							:href="$image(banner.attachment.attachedLink)"
							class="tw-bg-white tw-transform tw-scale-0 group-hover:tw-scale-100 tw-transition-all tw-delay-75 tw-duration-300"
						>
							<v-icon size="24" color="success">mdi-eye</v-icon></v-btn
						>
						<v-btn
							:loading="deleting === banner.id"
							icon
							width="64"
							height="64"
							class="tw-bg-white tw-transform tw-scale-0 group-hover:tw-scale-100 tw-transition-all tw-delay-150 tw-duration-300"
							@click="onDeleteBanner(banner.id)"
						>
							<v-icon size="24" color="red">mdi-delete</v-icon></v-btn
						>
					</div>
					<v-img
						:aspect-ratio="16 / 5"
						:src="$image(banner.attachment.attachedLink)"
					/>
				</v-card>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { openFilePicker, toFormData } from 'vuelpers'
export default {
	name: 'Banners',
	data() {
		return {
			deleting: false,
			isCreating: false,
		}
	},
	created() {
		this.getBanners()
	},
	computed: {
		...mapGetters('banners', ['$banners']),
	},
	methods: {
		...mapActions('banners', ['getBanners', 'createBanner', 'deleteBanner']),
		onCreateBanner() {
			openFilePicker({}, async (attachment) => {
				console.log('onCreateBanner', attachment)
				this.isCreating = true
				await this.createBanner(toFormData({ image: attachment.file }))
				this.isCreating = false
			})
		},
		async onDeleteBanner(id) {
			if (confirm(this.$t('are-you-sure'))) {
				this.deleting = id
				await this.deleteBanner(id)
				this.deleting = false
			}
		},
	},
}
</script>
